import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Forgot from './auth/Forgot';
import ResetPassword from './auth/ResetPassword';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import Verify from './auth/Verify';
import PrivacyPolicy from './privacy/PrivacyPolicy';

const Login = () => {
    return (
        <Routes>
            <Route path='/' element={<SignIn />} />
            <Route path='/new' element={<SignUp />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/confirm' element={<Verify />} />
            <Route path='/forgot' element={<Forgot />} />
            <Route path='/reset' element={<ResetPassword />} />
        </Routes>
    );
};

export default Login;
