import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

import { auth } from '../../functions';
import Logos from '../Logos';
import Loading from '../svg/Loading';

const Forgot = () => {
    const recaptchaRef = React.createRef();
    const [loading, setLoading] = React.useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();

        // get form data
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        const token = await recaptchaRef.current.executeAsync();
        const body = { ...data, google: token };

        setLoading(true);
        const id = toast.loading('Processing...');

        auth.forgotPassword(body)
            .then((res) => {
                setLoading(false);

                toast.update(id, {
                    render: 'Correo enviado...',
                    type: 'success',
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    autoClose: 5000
                });
            })
            .catch(() => {
                toast.update(id, {
                    render: 'Hemos tenido un problema enviando el correo.',
                    type: 'error',
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    autoClose: 5000
                });
                setLoading(false);
            });
    };

    return (
        <>
            <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
                <Logos />

                <div className='w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-stone-200 border-stone-700'>
                    <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
                        <h1 className='text-xl font-sans font-bold leading-tight tracking-tight md:text-2xl text-white'>
                            Ingresa tu correo
                        </h1>

                        <div className='text-sm text-gray-300'>
                            Enviaremos un correo con un enlace para que puedas
                            crear una nueva contraseña.
                        </div>

                        <form
                            className='auth_form'
                            action='#'
                            onSubmit={onSubmit}
                        >
                            <div>
                                <label for='identifier' className='label'>
                                    Correo
                                </label>
                                <input
                                    type='email'
                                    name='email'
                                    id='email'
                                    className='input'
                                    placeholder='snake / hello@snake.rip'
                                    required
                                />
                            </div>

                            <button
                                disabled={loading}
                                type='submit'
                                className='button loading font-sans'
                            >
                                <div>{loading ? <Loading /> : 'Enviar'}</div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={'6Lf7_EwfAAAAAD3RFCWt1Zfvmf6s9Z5nH6GoOwFw'}
            />
        </>
    );
};

export default Forgot;
