import React from 'react';

const Logos = () => {
    return (
        <div className='flex items-center mb-6 text-3xl font-semibold text-white gap-7'>
            <img className='w-24 h-24' src='/images/hycraft.png' alt='logo' />
            x
            <img
                className='w-24 h-24'
                src='/images/lightcloud.png'
                alt='logo'
            />
        </div>
    );
};

export default Logos;
