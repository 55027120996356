import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { redirect, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { auth } from '../../functions';
import Logos from '../Logos';
import Loading from '../svg/Loading';

const ResetPassword = () => {
    const recaptchaRef = React.createRef();

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!searchParams.get('token')) {
            navigate('/');
            return;
        }
    }, [navigate, searchParams]);

    const onSubmit = async (event) => {
        event.preventDefault();

        // get form data
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        const token = await recaptchaRef.current.executeAsync();
        const body = { ...data, google: token };

        if (body.password !== body.confirm_password) {
            toast.error('Las contraseñas no coinciden.');
            return;
        }

        setLoading(true);
        const id = toast.loading('Processing...');

        auth.resetPassword(searchParams.get('token'), body)
            .then((res) => {
                setLoading(false);

                let { statusCode, message } = res;
                setLoading(false);

                if (statusCode) {
                    toast.update(id, {
                        render: message,
                        type: 'error',
                        isLoading: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        autoClose: 5000
                    });
                    return;
                }

                toast.update(id, {
                    render: 'Password changed...',
                    type: 'success',
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    autoClose: 5000
                });

                setTimeout(() => {
                    redirect('/');
                }, 5000);
            })
            .catch(() => {
                toast.update(id, {
                    render: 'Hemos tenido un problema en la respuesta...',
                    type: 'error',
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    autoClose: 5000
                });
                setLoading(false);
            });
    };

    return (
        <>
            <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
                <Logos />

                <div className='w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-stone-200 border-stone-700'>
                    <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
                        <h1 className='text-xl font-sans font-bold leading-tight tracking-tight md:text-2xl text-white'>
                            Ingresa tu nueva contraseña
                        </h1>

                        <div className='text-sm text-gray-300'>
                            Ingresa tu nueva contraseña y confirma para
                            cambiarla.
                        </div>

                        <form
                            className='auth_form'
                            action='#'
                            onSubmit={onSubmit}
                        >
                            <div>
                                <label for='identifier' className='label'>
                                    Contraseña
                                </label>
                                <input
                                    type='password'
                                    name='password'
                                    id='password'
                                    className='input'
                                    placeholder='●●●●●●●'
                                    required
                                />
                            </div>

                            <div>
                                <label for='identifier' className='label'>
                                    Confirme contraseña
                                </label>
                                <input
                                    type='password'
                                    name='confirm_password'
                                    id='confirm_password'
                                    className='input'
                                    placeholder='●●●●●●●'
                                    required
                                />
                            </div>

                            <button
                                disabled={loading}
                                type='submit'
                                className='button loading font-sans'
                            >
                                <div>{loading ? <Loading /> : 'Enviar'}</div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={'6Lf7_EwfAAAAAD3RFCWt1Zfvmf6s9Z5nH6GoOwFw'}
            />
        </>
    );
};

export default ResetPassword;
