import React from 'react';

import { ToastContainer } from 'react-toastify';

import Login from './modules/Login';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    return (
        <>
            <Login />

            <script
                defer
                src='https://static.cloudflareinsights.com/beacon.min.js'
                data-cf-beacon='{"token": "0fde7f45336347ffa1d48976df2feb6d"}'
            />

            <div className='version'>
                <p>Version: 1.0.7</p>
            </div>

            <ToastContainer
                position='bottom-center'
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='dark'
            />
        </>
    );
};

export default App;
