import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { auth } from '../../functions';
import Logos from '../Logos';
import Loading from '../svg/Loading';

const SignUp = () => {
    const recaptchaRef = React.createRef();
    const navigator = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (!searchParams.get('redirect')) {
            setSearchParams({ redirect: 'https://hycraft.us/auth?token=%s' });
        }
    }, [searchParams, setSearchParams]);

    const onSubmit = async (event) => {
        event.preventDefault();
        const redirect = searchParams.get('redirect');
        if (!redirect) {
            toast.error('Invalid redirect URL');
            return;
        }

        // get form data
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        const token = await recaptchaRef.current.executeAsync();
        const body = { ...data, google: token };

        setLoading(true);
        const id = toast.loading('Processing...');

        auth.register(body)
            .then((response) => {
                const { statusCode, message } = response;
                setLoading(false);

                if (statusCode) {
                    toast.update(id, {
                        render: message,
                        type: 'error',
                        isLoading: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        autoClose: 5000
                    });
                    return;
                }

                toast.update(id, {
                    render: message,
                    type: 'success',
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    autoClose: 5000
                });

                navigator(`/?${searchParams.toString()}`);
            })
            .catch(() => {
                toast.update(id, {
                    render: 'An error occurred',
                    type: 'error',
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    autoClose: 5000
                });
                setLoading(false);
            });
    };

    return (
        <>
            <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
                <Logos />
                <div className='w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-stone-200 border-stone-700'>
                    <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
                        <h1 className='text-xl font-sans font-bold leading-tight tracking-tight md:text-2xl text-white'>
                            Crea tu cuenta
                        </h1>
                        <form className='auth_form' onSubmit={onSubmit}>
                            <div>
                                <label for='email' className='label'>
                                    Correo
                                </label>
                                <input
                                    type='email'
                                    name='email'
                                    id='email'
                                    className='input'
                                    placeholder='hello@snake.rip'
                                    required
                                />
                            </div>

                            <div>
                                <label for='displayName' className='label'>
                                    Nombre
                                </label>
                                <input
                                    type='text'
                                    name='displayName'
                                    id='displayName'
                                    className='input'
                                    placeholder='Martín'
                                    required
                                />
                            </div>
                            <div>
                                <label for='username' className='label'>
                                    Apodo{' '}
                                    <span className='text-gray-400'>
                                        (Numeros y Letras)
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    name='username'
                                    id='username'
                                    className='input'
                                    placeholder='Snake'
                                    pattern='[a-zA-Z0-9]+'
                                    min={3}
                                    maxLength={16}
                                    required
                                />
                            </div>
                            <div>
                                <label for='password' className='label'>
                                    Contraseña
                                </label>
                                <input
                                    type='password'
                                    name='password'
                                    id='password'
                                    placeholder='••••••••'
                                    className='input'
                                    required
                                />
                            </div>

                            <div className='flex items-center justify-between'>
                                <div className='flex items-start'>
                                    <div className='flex items-center h-5'>
                                        <input
                                            id='terms'
                                            aria-describedby='terms'
                                            type='checkbox'
                                            className='check_box'
                                            required
                                        />
                                    </div>
                                    <div className='ml-3 text-sm'>
                                        <label
                                            for='terms'
                                            className='text-gray-300'
                                        >
                                            Acepto los terminos y condiciones (
                                            <Link
                                                to='/privacy-policy'
                                                target={'_blank'}
                                                className='text-blue-500'
                                            >
                                                Leer
                                            </Link>
                                            )
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button
                                disabled={loading}
                                type='submit'
                                className='button loading font-sans'
                            >
                                <div>
                                    {loading ? <Loading /> : 'Registrarse'}
                                </div>
                            </button>
                            <p className='text-sm font-light text-gray-400'>
                                Ya tienes una cuenta?{' '}
                                <Link
                                    to={`/?${searchParams.toString()}`}
                                    className='font-medium hover:underline text-primary-500'
                                >
                                    Ingresa
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>

            <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={'6Lf7_EwfAAAAAD3RFCWt1Zfvmf6s9Z5nH6GoOwFw'}
            />
        </>
    );
};

export default SignUp;
