import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { auth } from '../../functions';
import Logos from '../Logos';
import Loading from '../svg/Loading';

const SignIn = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const recaptchaRef = React.createRef();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (!searchParams.get('redirect')) {
            setSearchParams({ redirect: 'https://hycraft.us/auth?token=%s' });
        }
    }, [searchParams, setSearchParams]);

    const onSubmit = async (event) => {
        event.preventDefault();
        const redirect = searchParams.get('redirect');
        if (!redirect) return;

        // get form data
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        const token = await recaptchaRef.current.executeAsync();
        const body = { ...data, google: token };

        setLoading(true);
        const id = toast.loading('Processing...');

        auth.login(body)
            .then((response) => {
                let { statusCode, access_token, public_token, message } =
                    response;
                setLoading(false);

                if (statusCode) {
                    toast.update(id, {
                        render: message,
                        type: 'error',
                        isLoading: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        autoClose: 5000
                    });
                    return;
                }

                toast.update(id, {
                    render: 'Redirecting...',
                    type: 'success',
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    autoClose: 5000
                });

                if (access_token === 'NO_HOVER_PASS')
                    access_token = public_token;

                let auth_redirect = redirect.replace(/%s/, public_token);
                auth_redirect = auth_redirect.replace(/%s/, access_token);

                console.log('auth_redirect', auth_redirect);
                window.location.href = auth_redirect;
            })
            .catch(() => {
                toast.update(id, {
                    render: 'Username, email or password are incorrect',
                    type: 'error',
                    isLoading: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    autoClose: 5000
                });
                setLoading(false);
            });
    };

    return (
        <>
            <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
                <Logos />

                <div className='w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-stone-200 border-stone-700'>
                    <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
                        <h1 className='text-xl font-sans font-bold leading-tight tracking-tight md:text-2xl text-white'>
                            Ingresa en tu cuenta
                        </h1>
                        <form
                            className='auth_form'
                            action='#'
                            onSubmit={onSubmit}
                        >
                            <div>
                                <label for='identifier' className='label'>
                                    Usuario / Correo
                                </label>
                                <input
                                    type='text'
                                    name='identifier'
                                    id='identifier'
                                    className='input'
                                    placeholder='snake / hello@snake.rip'
                                    required
                                />
                            </div>
                            <div>
                                <label for='password' className='label'>
                                    Contraseña
                                </label>
                                <input
                                    type='password'
                                    name='password'
                                    id='password'
                                    placeholder='••••••••'
                                    className='input'
                                    required
                                />
                            </div>

                            <div className='flex items-center justify-between'>
                                <div className='flex items-start'>
                                    <div className='flex items-center h-5'>
                                        <input
                                            id='secret'
                                            aria-describedby='secret'
                                            type='checkbox'
                                            className='check_box'
                                        />
                                    </div>
                                    <div className='ml-3 text-sm'>
                                        <label
                                            for='secret'
                                            className='text-gray-300'
                                        >
                                            Secreto
                                        </label>
                                    </div>
                                </div>

                                <Link
                                    to={`/forgot?${searchParams.toString()}`}
                                    className='text-sm font-medium  hover:underline text-primary-500'
                                >
                                    No puedes ingresar?
                                </Link>
                            </div>
                            <button
                                disabled={loading}
                                type='submit'
                                className='button loading font-sans'
                            >
                                <div>{loading ? <Loading /> : 'Ingresar'}</div>
                            </button>
                            <p className='text-sm font-light text-gray-400'>
                                No tienes cuenta aun?{' '}
                                <Link
                                    to={`/new?${searchParams.toString()}`}
                                    className='font-medium hover:underline text-primary-500'
                                >
                                    Registrarse
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>

            <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={'6Lf7_EwfAAAAAD3RFCWt1Zfvmf6s9Z5nH6GoOwFw'}
            />
        </>
    );
};

export default SignIn;
