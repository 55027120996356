import React from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth } from '../../functions';
import Logos from '../Logos';

const Verify = () => {
    const [searchParams] = useSearchParams();
    const [status, setStatus] = React.useState({
        loading: true,
        error: false,
        message: 'Verifying your account...'
    });
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!searchParams.get('token')) {
            setStatus({
                loading: false,
                error: true,
                message: 'Invalid verification token'
            });
            return;
        }

        const token = searchParams.get('token');
        auth.verifyEmail(token)
            .then((res) => {
                if (!res.validated) {
                    setStatus({
                        loading: false,
                        error: true,
                        message: 'Invalid verification token'
                    });
                    return;
                }

                setStatus({
                    loading: false,
                    error: false,
                    message: 'Account verified, redirecting...'
                });
            })
            .catch((err) => {
                let message = 'Invalid verification token';

                if (err.message === 'Network Error') {
                    message = 'Unable to connect to the server';
                }

                setStatus({
                    loading: false,
                    error: true,
                    message: message
                });
            })
            .finally(() => {
                setTimeout(() => {
                    navigate('/');
                }, 5000);
            });
    }, [navigate, searchParams]);

    const getMessage = () => {
        return <div>{status.message}</div>;
    };

    return (
        <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
            <Logos />

            <div className='w-full rounded-lg md:mt-0 sm:max-w-md xl:p-0 '>
                <div
                    className={`grid place-items-center rounded-lg text-xl font-sans font-bold leading-tight tracking-tight md:text-2xl text-white ${
                        status.loading
                            ? 'bg-gray-800'
                            : status.error
                            ? 'bg-red-800'
                            : 'bg-green-800'
                    } py-2`}
                    disabled
                >
                    {getMessage()}
                </div>
            </div>
        </div>
    );
};

export default Verify;
