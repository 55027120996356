async function login(body) {
    // fetch post request to login
    return fetch('https://warrior.hycraft.us/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then((res) => res.json())
        .catch((err) => console.log(err));
}

async function register(body) {
    return fetch('https://warrior.hycraft.us/auth/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then((res) => res.json())
        .catch((err) => console.log(err));
}

async function verifyEmail(token) {
    return fetch(`https://warrior.hycraft.us/auth/verify?token=${token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.json())
        .catch((err) => console.log(err));
}

async function forgotPassword(body) {
    return fetch('https://warrior.hycraft.us/auth/forgot', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then((res) => res.json())
        .catch((err) => console.log(err));
}

async function resetPassword(token, body) {
    return fetch('https://warrior.hycraft.us/auth/reset-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ...body,
            token
        })
    })
        .then((res) => res.json())
        .catch((err) => console.log(err));
}

// export functions to be used in other files
export { login, register, verifyEmail, forgotPassword, resetPassword };
